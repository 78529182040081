import {
  ALL_FACETS,
  FilterOnlyFacets,
  MultiRangeFacets,
  RefinementFacets,
} from "./refinements";
import { InstantSearchContext, InstantSearchContextInterface } from "./context";
import { useEffect, useState } from "react";
import * as React from "react";
import {
  trackOfferFilterEvent,
  trackOfferSearchEvent,
} from "../../utils/event-tracking";

import { InstantSearch } from "react-instantsearch-core";
import algoliasearch from "algoliasearch";
import { initialState } from "./state";
import { navigate } from "gatsby";
import qs from "qs";
import { useLocation } from "@reach/router";
import vinoFetch from "../../utils/vinoFetch";
import useAuth from "../../hooks/use-auth";
import { determineTierIndex } from "../../utils/loyalty";
import { VinoUser } from "../../contexts/auth/state";
import { useLoyalty } from "../../hooks/account/use-loyalty";

export interface InstantSearchProviderProps {
  children: React.ReactNode;
  isFeatured?: boolean;
}

export const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

const getSortSlug = (value: string): string | null => {
  const regex = /price_(asc|desc)$/;
  return regex.test(value) ? value.match(regex)![0] : null;
};

const getSortValue = (sortOrder: string, baseIndex: string): string => {
  return sortOrder === "price_desc" || sortOrder === "price_asc"
    ? `${baseIndex}_${sortOrder}`
    : baseIndex;
};

const noRefinements = (refinementList): boolean =>
  !refinementList ||
  ALL_FACETS.every(({ attribute }) => refinementList[attribute]?.length === 0);

const noSorting = (sortBy, index): boolean => !sortBy || sortBy === index;

const isDefaultRoute = (state): boolean =>
  !state.query &&
  !state.toggle &&
  state.page === 1 &&
  noRefinements(state.refinementList) &&
  noSorting(state.sortBy);

type FilterQueryParams = {
  query?: string;
  sortBy?: string;
  toggle?: { [key: string]: string };
  page?: number;
};

const createURL = (location, state, index) => {
  if (isDefaultRoute(state)) {
    return "";
  }

  const queryParameters: FilterQueryParams = {};

  if (state.query) {
    queryParameters.query = encodeURIComponent(state.query);
  }

  if (state.sortBy && state.sortBy !== index) {
    queryParameters.sortBy = getSortSlug(state.sortBy);
  }

  if (state.page !== 1) {
    queryParameters.page = state.page;
  }

  if (state.toggle) {
    queryParameters.toggle = Object.keys(state.toggle).reduce((acc, key) => {
      if (state.toggle[key]) {
        return {
          ...acc,
          [key]: state.toggle[key],
        };
      }
      return acc;
    }, {});
  }

  if (state?.refinementList) {
    RefinementFacets.forEach(({ attribute, urlKey }) => {
      if (state.refinementList[attribute]) {
        queryParameters[urlKey] = state.refinementList[attribute].map(
          encodeURIComponent
        );
      }
    });
  }

  if (state?.multiRange) {
    MultiRangeFacets.forEach(({ attribute, urlKey }) => {
      if (state.multiRange[attribute]) {
        queryParameters[urlKey] = encodeURIComponent(
          state.multiRange[attribute]
        );
      }
    });
  }

  if (state?.refinementList) {
    FilterOnlyFacets.forEach(({ attribute, urlKey }) => {
      if (state.refinementList[attribute]) {
        if (state.refinementList[attribute]?.includes("true")) {
          return;
        }
        queryParameters[urlKey] = state.refinementList[attribute].map(
          encodeURIComponent
        );
      }
    });
  }

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: "repeat",
    skipNulls: true,
  });

  return state.query
    ? `/wines${queryString}`
    : `${location.pathname}${queryString}`;
};

const searchStateToUrl = (location, searchState) => {
  return searchState.page ? `${createURL(location, searchState)}` : "";
};

const urlToSearchState = (location, index) => {
  let { query = "", sortBy = "", page = 1, toggle = {}, ...params } = qs.parse(
    location.search.slice(1)
  ) as FilterQueryParams;
  if (sortBy === "" || sortBy === "popular") {
    sortBy = index;
  }

  const refinementList = RefinementFacets.reduce(
    (acc, { attribute, urlKey }) => {
      const paramValue = params[urlKey];

      if (!paramValue) return acc;

      const refinement = Array.isArray(paramValue)
        ? paramValue.map(decodeURIComponent)
        : [paramValue].filter(Boolean).map(decodeURIComponent);

      acc[attribute] = refinement;
      return acc;
    },
    {}
  );

  const multiRange = MultiRangeFacets.reduce((acc, { attribute, urlKey }) => {
    const paramValue = params[urlKey];

    if (!paramValue) {
      return acc;
    }

    const refinement = decodeURIComponent(paramValue);

    acc[attribute] = refinement;
    return acc;
  }, {});

  const FilterOnly = FilterOnlyFacets.reduce((acc, { attribute, urlKey }) => {
    const paramValue = params[urlKey];

    if (!paramValue) return acc;

    const refinement = Array.isArray(paramValue)
      ? paramValue.map(decodeURIComponent)
      : [paramValue].filter(Boolean).map(decodeURIComponent);

    acc[attribute] = refinement;
    return acc;
  }, {});

  return {
    query: decodeURIComponent(query),
    originalQuery: decodeURIComponent(query),
    hasResult: false,
    page,
    sortBy: getSortValue(sortBy, index),
    toggle,
    refinementList: { ...refinementList, ...FilterOnly },
    multiRange,
  };
};

export const InstantSearchProvider = ({
  children,
  isFeatured = false,
}: InstantSearchProviderProps): JSX.Element => {
  const location = useLocation();
  const { user } = useAuth();
  const { loyalty, showLoyalty } = useLoyalty();
  const { index, tierFilter } = determineTierIndex(
    user as VinoUser,
    showLoyalty,
    loyalty?.loyalty_tier
  );

  const [searchState, setSearchState] = useState<any>(
    urlToSearchState(location, index)
  );
  const [searching, setSearching] = useState<boolean>(false);
  const [hasResult, setHasResult] = useState<boolean>(false);
  const [searchedKeyword, setSearchedKeyword] = useState<string>("");
  const [currentPathname, setCurrentPathname] = useState<string>("");
  const [carryOverFilter, setCarryOverFilter] = useState<string>(
    location?.search.replace(/query=[^&]+&?/, "")
  );
  const [currentFilterGroup, setCurrentFilterGroup] = useState("");
  const toggleFilterGroup = (id: string) => {
    setCurrentFilterGroup(id);
  };

  const doPreSearch = async (keyword, index) => {
    if (keyword.length > 0) {
      console.log("RUNNING QUERY ==> ");
      const {
        data,
      }: { data: { count: any; keyword: string } } = await vinoFetch.get(
        "/api/pre-search-algolia",
        {
          params: {
            index,
            keyword: keyword,
          },
        }
      );
      trackOfferSearchEvent(
        "Products Searched",
        keyword,
        `${location?.origin}${currentPathname}`,
        data && data.count > 0 ? data.count : 0
      );
      setSearchedKeyword(keyword);
      if (data) {
        setHasResult(data.count > 0);
      } else {
        setHasResult(false);
      }
      setSearching(false);
    }
  };

  const onSearchStateChange = async (nextSearchState) => {
    if (
      nextSearchState?.refinementList &&
      Object.keys(nextSearchState?.refinementList).length > 0 &&
      JSON.stringify(searchState?.refinementList) !==
        JSON.stringify(nextSearchState?.refinementList)
    ) {
      trackOfferFilterEvent("Product List Filtered", nextSearchState);
    }

    const isUrlChanging =
      searchStateToUrl(location, searchState) !==
      searchStateToUrl(location, nextSearchState);

    if (isUrlChanging) {
      if (location?.pathname !== currentPathname)
        setCurrentPathname(location?.pathname);

      const url = searchStateToUrl(location, nextSearchState);

      if (url) {
        navigate(url, {
          state: nextSearchState,
          replace: true,
        });
      }

      return;
    }
  };

  useEffect(() => {
    setCurrentPathname(location?.pathname);
    console.log("INIT INSTANTSEARCH PROVIDER");
  }, []);

  useEffect(() => {
    let nextSearchState = urlToSearchState(location, index);
    if (
      JSON.stringify(searchState) !== JSON.stringify(nextSearchState) ||
      location?.pathname !== currentPathname
    ) {
      if (
        nextSearchState?.query &&
        nextSearchState?.query !== searchedKeyword &&
        !searching &&
        !isFeatured
      ) {
        console.log("INITIATE QUERY ==> ");
        setSearching(true);
        doPreSearch(nextSearchState.query, nextSearchState.sortBy);
      }

      if (!hasResult) {
        nextSearchState = {
          ...nextSearchState,
          query: "",
          originalQuery: searchedKeyword,
          hasResult: false,
        };
      } else {
        nextSearchState = {
          ...nextSearchState,
          hasResult: true,
        };
      }

      if (location?.pathname !== currentPathname) {
        setSearchedKeyword("");
        setCarryOverFilter(location?.search.replace(/query=[^&]+&?/, ""));
      }

      setSearchState(nextSearchState);
    }
  }, [location, index]);

  useEffect(() => {
    let nextSearchState = urlToSearchState(location, index);
    if (!hasResult) {
      nextSearchState = {
        ...nextSearchState,
        query: "",
        originalQuery: searchState?.query,
        hasResult: true,
      };
    } else {
      nextSearchState = {
        ...nextSearchState,
        hasResult: true,
      };
    }
    setSearchState(nextSearchState);
  }, [hasResult, index]);

  const ctx: InstantSearchContextInterface = {
    ...initialState,
    searchState,
    searching,
    hasResult,
    carryOverFilter,
    tierFilter,
    doPreSearch,
    onSearchStateChange,
    setCarryOverFilter,
    currentFilterGroup,
    toggleFilterGroup,
  };

  return (
    <InstantSearch
      indexName={index || ""}
      searchClient={searchClient}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
      stalledSearchDelay={500}
    >
      <InstantSearchContext.Provider value={ctx}>
        {children}
      </InstantSearchContext.Provider>
    </InstantSearch>
  );
};
